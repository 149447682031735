// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Link from '../_modules/link/link';
import News from '../_modules/news/news';
import Header from '../_modules/header/header';
import Logos from '../_modules/logo-slider/logo-slider';

$(() => {
  new Link(); // Activate Link modules logic
  new News();
  new Header();
  new Logos();
});
