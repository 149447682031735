'use strict';
import $ from 'jquery';

export default class News {
  constructor() {
    this.name = 'news';

    var mySwiper = new Swiper ('.swiperContainer', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      spaceBetween: 20,
      speed: 400,
      slidesPerView: 1,
      paginationClickable: true,
      effect: 'fade',
      
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },

      fadeEffect: {
        crossFade: true
      },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      // Navigation arrows
        navigation: {
          nextEl: '.slider-next',
          prevEl: '.slider-prev',
        },
      
    });
  }
}
