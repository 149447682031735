'use strict';

export default class LogoSlider {
  constructor() {
    this.name = 'logo-slider';
    var mySwiper2 = new Swiper ('.swiperLogoContainer', {
      // Optional parameters
      slidesPerView: 5,
        // pagination: {
          // el: '.swiper-pagination-logo',
          // clickable: true,
        // },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });
  }
}
