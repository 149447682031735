'use strict';

import $ from 'jquery';
export default class Header {
  constructor() {
    this.name = 'header';
    $('.headerNav-hamburger').on('click', function(){
      $('.headerNav-ul').toggleClass('active');
    });
  }
}
